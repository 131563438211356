import React from 'react';
import GoogleMapComponent from './GoogleMapComponent';
import 'leaflet/dist/leaflet.css';
import { size } from 'lodash';


const audioFile = require('../src/audios/Y2meta.app - Michael Bublé - Feeling Good [Official 4K Remastered Music Video] (128 kbps).mp3');

const Footer=() => {

    return(

        <footer id="contacto">
        <div className="footer-bg">
          <h2>CONTÁCTANOS</h2>
          <form>
            <div className="campo-1">
              <input className="campo" type="text" placeholder="Nombre" />
              <input className="campo" type="number" placeholder="Telefono" />
            </div>
            <div className="campo-1">
              <input className="campo" type="text" placeholder="Direccion" />
              <input className="campo" type="email" placeholder="Correo" />
            </div>
            <textarea className="campo" cols="30" rows="10" placeholder="Texto"></textarea>
            <input type="submit" value="Enviar" className="btn-1" />
          </form>
              <div className="mapzoom">
            <GoogleMapComponent />
              </div>
        </div>
     

        <div className="footer-txt">
          <p>
            Derechos Reservados 2024@
            <a href="#">Volver al inicio</a>
          </p>
        </div>
        <div className="audio-container">
          <audio controls>
            <source src={audioFile} type="audio/mpeg" />
            Tu navegador no soporta el elemento de audio.
          </audio>
        </div>
      </footer>

    )

}
export default Footer;