import "bootstrap/dist/css/bootstrap.min.css"

import Header from "./Header"
import Section from "./Section"
import Section2 from "./Section2"
import Section3 from "./Section3"
import Main from "./Main"
import Footer from "./Footer"
import './App.css';

function App() {
  return (
    <div >
      <Header/>
      <Section/>
      <Main/>
      <Section2/>
      <Section3/>
      <Footer/>
    </div>
  );
}

export default App;
