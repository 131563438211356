import React from 'react';

const Section=() => {

    return(

        <section className="welcome">
        <div className="welcome-1">
          {/* Puedes añadir contenido o imágenes aquí */}
        </div>
        <div id="inicio" className="welcome-2">
          <h2>Bienvenidos a ECOWASH</h2>
          <p className="p1">
            Ecowash ofrece el mejor servicio de limpieza al detalle y cuidado automotriz formando
             jóvenes emprendedores para convertirlos en verdaderos especialistas del detailing vehicular, 
             diferenciándonos por nuestro servicio personalizado, culturizando a nuestros clientes sobre 
             la importancia y relevancia del cuidado de vehículos.
          </p>
          <p>
          Consolidarnos como el centro especializado líder en la limpieza y cuidado de vehículos, 
          promoviendo el desarrollo del detailing en nuestro país.
          </p>
          <a href="#" className="btn-1">Ir a Inicio</a>
        </div>
      </section>

    )

}
export default Section;
