import React from 'react';

const barber1 = require('../src/imagenes/b1.jpg');
const barber2 = require('../src/imagenes/b2.jpg');


const barber3 = require('../src/imagenes/b3.jpg');
const facebookIcon = require('../src/imagenes/facebook.png');
const tiktokIcon = require('../src/imagenes/tik-tok.png');
const whatsappIcon = require('../src/imagenes/whatsapp.png');
const instagramIcon = require('../src/imagenes/instagram.png');



const Section3=() => {

    return(
        <section id="barberos" className="personal container">
      <div className="personal-txt">
        <h2>Nuestros Barberos</h2>
        <hr />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque porro debitis autem alias quaerat a cupiditate beatae nisi neque. Adipisci velit minima dignissimos? Deserunt vel, laboriosam obcaecati odio esse illo.
        </p>
      </div>
      <div className="personal-group">
        <div className="personal-1">
          <img src={barber1} alt="Barbero Marcos" />
          <h3>Marcos</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores quis aut voluptatum voluptatibus sit tempore sapiente saepe nihil, soluta, provident aliquam dolorem porro, cupiditate obcaecati dolor. Eius rem itaque dolore.
          </p>
          <a href="#">Saber más</a>
        </div>
        <div className="personal-1">
          <img src={barber2} alt="Barbero Michael" />
          <h3>Michael</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores quis aut voluptatum voluptatibus sit tempore sapiente saepe nihil, soluta, provident aliquam dolorem porro, cupiditate obcaecati dolor. Eius rem itaque dolore.
          </p>
          <a href="#">Saber más</a>
        </div>
        <div className="personal-1">
          <img src={barber3} alt="Barbero Piero" />
          <h3>Piero</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores quis aut voluptatum voluptatibus sit tempore sapiente saepe nihil, soluta, provident aliquam dolorem porro, cupiditate obcaecati dolor. Eius rem itaque dolore.
          </p>
          <a href="#">Saber más</a>
        </div>
      </div>
      <a href="#" className="btn-1">Información</a>
      <div className="social-icons">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <img src={facebookIcon} alt="Facebook" />
        </a>
        <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
          <img src={tiktokIcon} alt="TikTok" />
        </a>
        <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
          <img src={whatsappIcon} alt="WhatsApp" />
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <img src={instagramIcon} alt="Instagram" />
        </a>
      </div>
    </section>
        

    )

}
export default Section3;