import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
 

// Asegúrate de que esta sea la ruta correcta a tu ícono personalizado.
import customIconUrl from './imagenes/logo.png';

// Definir un ícono personalizado utilizando Leaflet
const customIcon = new L.Icon({
  iconUrl: customIconUrl,
  iconSize: [50, 50], // Tamaño del ícono
  iconAnchor: [16, 32], // Punto del ícono que se anclará al marcador (mitad inferior)
  popupAnchor: [0, -32], // Posición del popup en relación con el icono
  className: 'blinking-icon',
});

const GoogleMapComponent = () => {
  // Coordenadas de ejemplo: puedes cambiarlas a las que desees.
  const position = [-12.0572689,-77.0972936];
  const wazeUrl = "https://waze.com/ul?ll=-12.0572689,-77.0972936&navigate=yes";

  const handleMarkerClick = () => {
    // Abre Waze en una nueva pestaña o ventana
    window.open(wazeUrl, '_blank');
  };


  return (
    <MapContainer center={position} zoom={20} style={{ height: '400px', width: '50%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
       />
      <Marker position={position} icon={customIcon} eventHandlers={{ click: handleMarkerClick }}>
        <Popup>
          ECO WASH<br /> HORA DE ATENCION: Lun - Sáb: de 9:00 a 18:00
        </Popup>
      </Marker>
    </MapContainer>
  );
}

export default GoogleMapComponent;
