import React from 'react';
import '../src/Css/style.css';

const serviceImage1 = require('../src/imagenes/i4.svg');
const serviceImage2 = require('../src/imagenes/i2.svg');
const serviceImage3 = require('../src/imagenes/i3.svg');
const serviceVideo = require('../src/imagenes/WhatsApp Video 2024-07-05 at 12.21.49 PM.mp4');

const Main=() => {

    return(


<main id="servicios" className="services container">
      <div className="services-txt">
        <h2>Nuestros Servicios</h2>
        <hr />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam at tempore sint facilis, ducimus expedita ratione ea fuga ex, laborum sapiente a accusamus impedit quibusdam nobis cupiditate possimus esse minus!
        </p>
      </div>
      <div className="services-group">
        <div className="services-1">
          <img src={serviceImage1} alt="Icono del Servicio 1" />
          <h3>Servicio 1</h3>
          <p>
          Servicios de limpieza frecuentes que se realizan en un menor tiempo. Son servicios de mantenimiento que no incluyen limpiezas profundas. Recomendado 1 vez por semana o cada 10 días.
          </p>
          <a href="#">Saber más</a>
        </div>
        <div className="services-1">
          <img src={serviceImage2} alt="Icono del Servicio 2" />
          <h3>Servicio 2</h3>
          <video controls>
            <source src={serviceVideo} type="video/mp4" />
            Tu navegador no soporta el elemento de video.
          </video>
          <a href="#">Saber más</a>
        </div>
        <div className="services-1">
          <img src={serviceImage3} alt="Icono del Servicio 3" />
          <h3>Servicio 3</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero aliquid, odio deserunt distinctio harum sit perspiciatis placeat repellat quisquam alias. Laborum vitae adipisci culpa aliquam repudiandae rerum magnam nemo quia?
          </p>
          <a href="#">Saber más</a>
        </div>
      </div>
      <a href="#" className="btn-1">Ir a Inicio</a>
    </main>
        )

    }
    export default Main;