import React from 'react';

const Section2=() => {

    return(
    <section className="prices">
      <div className="prices-1">
        <h2>Lista de precios</h2>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima maxime in perferendis quas, enim eveniet aliquam, quisquam dolorum laboriosam sed vero id quam saepe eligendi error architecto accusantium similique repudiandae.
        </p>
        <table>
          <tbody>
            <tr>
              <th>Corte</th>
              <td>s/.40</td>
            </tr>
            <tr>
              <th>Tinte</th>
              <td>s/.140</td>
            </tr>
            <tr>
              <th>A domicilio</th>
              <td>s/.80</td>
            </tr>
          </tbody>
        </table>
        <a href="#" className="btn-1">Información</a>
      </div>
      <div className="prices-2">
        {/* Aquí puedes agregar más contenido o dejarlo vacío */}
      </div>
    </section>
        

    )

}
export default Section2;
